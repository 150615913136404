.MultiLanguageText {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.MultiLanguageText >label {
    font-weight: 600;
}

.MultiLanguageText-language-input {
    display: grid;
    grid-template-columns: 40px auto;
    margin-bottom: 0.5rem;
}

.MultiLanguageText-language-input label {
    grid-row-start: 1;
}

.MultiLanguageText-language-input input {
    grid-row-start: 1;
}