:root {
  --ac-chip-removable-action-bg: #1f836e;
  --ac-chip-removable-action-hover-bg: #175e4f;
  --ac-chip-toggle-pressed-bg: #175e4f;
  --ac-chip-toggle-pressed-hover-bg: #175e4f;
  --ac-chip-toggle-bg: #f3acac;
  --ac-chip-toggle-hover-bg: #f3acac;
  --a-spacing-4: 0.75em;
}

html,
body {
  height: 100%;
  margin: 0px;
  padding: 0px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
}

body {
  height: 100%;
  background: #f5f5f5;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-grow-2 {
  flex-grow: 2;
}

label {
  height: 100%;
  overflow-y: hidden;
}

.app-bottom-menu {
  position: fixed;
  bottom: 0;
  width: 100%;
  border-top: 1px solid white;
  height: 48px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  z-index: 1000;
  background-color: white;
}

.app-bottom-menu a {
  text-decoration: none;
  color: #175e4f;
}

.app-bottom-menu-title {
  margin-top: 4px;
}

.main-block {
  margin-bottom: 48px;
  height: 100%;
  overflow: auto;
}

.app-bottom-menu > div {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1;
  font-size: 12px;
}

.navds-tabs__tab--small {
  padding: 0.375rem 0.42rem !important;
}

.navds-tabs__tablist {
  flex-wrap: wrap;
}

.page-title {
  font-size: x-large;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.App {
  text-align: center;
}

.main-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-left: 0.5em;
  padding-right: 0.5em;
}

.button-language {
  background-image: linear-gradient(to bottom, #2dbb9d, #175e4f);
  margin-bottom: 2em;
  text-align: center;
  height: 7vh;
  border-radius: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.button-language a {
  color: white;
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}

.top-line {
  width: 100%;
  margin-top: 1em;
  margin-bottom: 1em;
}

.types-of-meal {
  display: flex;
  flex-direction: row;
  font-size: x-small;
}

.types-of-meal div {
  border: 1px solid #2dbb9d;
  color: #175e4f;
  padding: 1em;
  border-top-left-radius: 1.5em;
}
.types-of-meal .active {
  color: whitesmoke;
  background-color: #2dbb9d;
  padding: 1em;
  border-top-left-radius: 1.5em;
}

.recipe-alternatives {
  width: 100%;
}

.recipe-alternatives {
  width: 100%;
}

.recipe-alternatives {
  margin-top: 4em;
  width: 100%;
}

.recipe-alternatives button {
  width: 100%;
  border: 0;
}

.recipe-alternatives-view {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
}

.recipe-alternatives-view .recipe-alt-view {
  margin-bottom: 1em;
  padding: 0em;
  border: 0px solid green;

  width: 100%;
  border-radius: 1em 1em 0 1em;
  position: relative;
  background-color: #2dbb9d40;
}

.recipe-alternatives-view .recipe-alt-view .recipe-alt-view-image {
  border-bottom: 0px solid black;
  max-height: 15em;
  text-align: center;
}

.recipe-alternatives-view
  .recipe-alt-view
  .recipe-alt-view-image
  .recipe-alt-view-image-img {
  max-height: 15em;
  border-radius: 1em 1em 0 0em;
  max-width: 100%;
  width: 100%;
  border-bottom: 0px solid black;
}

.recipe-alternatives-view
  .recipe-alt-view
  .recipe-alt-view-image
  .recipe-alt-view-author {
  position: absolute;
  right: 0.1em;
  top: 0.5em;
  width: 3em;
}

.recipe-alternatives-view
  .recipe-alt-view
  .recipe-alt-view-image
  .recipe-alt-view-author
  .recipe-alt-view-author-img {
  max-width: 3em;
}

.recipe-alternatives-view .recipe-alt-view-description {
  width: 100%;
  overflow: hidden;
  padding-left: 0em;
  border-radius: 0em 0em 0 1em;
  text-align: center;
  display: flex;
  align-items: center;
  max-height: 5em;
  min-height: 5em;
}

.recipe-alternatives-view .recipe-alt-view-description .recipe-alt-view-title {
  width: 90%;
}

.recipe-alternatives-view .recipe-alt-view-description a {
  text-decoration: none;
  color: black;
}

* {
  /* overflow-y: hidden;  */
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* 防止水平滚动条出现 */
}
.sidebar-link {
  color: white;
  margin-top: 20px;
}
.sidebar-link:hover {
  background-color: #3ba936;
}

#sidebar-wrapper {
  background-color: #353744;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: fixed;
  /* 固定位置 */
  top: 0;
  bottom: 0;
  left: 0;
  width: 200px; /* 侧边栏宽度 */
}
#page-content-wrapper {
  margin-left: 200px; /* 与侧边栏宽度相同 */
}
