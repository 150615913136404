.RecipeTags {
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.RecipeTags > label {
    font-weight: 600;
}

.RecipeTags .RecipeTagsOptions {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.RecipeTags .RecipeTagsOptions .form-check {
    margin-right: 0.5rem;
    padding-right: 0.5rem;
    border-right: 1px solid silver;
}

.RecipeTags .RecipeTagsOptions label {
    height: auto;
}