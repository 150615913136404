.EditRecipe {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.EditRecipe .recipe-input {
    min-width: 50%;
    flex-grow: 2;
}

.EditRecipe .recipe-view {
    min-width: 400px;
}