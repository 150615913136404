
.IngredientItem {
    display: flex;
    width: 100%;
    margin-bottom: 1rem;
}

.IngredientItem--content {
    border: 1px solid grey;
    border-radius: 1rem;
    background-color: white;
    flex: 1;
    padding: 1rem;
}

.IngredientItem--content--id > label {
    font-weight: 600;
}