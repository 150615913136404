.RecipeViewForm {
    width: 100%;
    height: 100%;
    border-left: 1px solid grey;
}

.RecipeViewForm iframe {
    width: 100%;
    height: 100%;
    max-width: 400px;
    max-height: 2000px;
}