.searchheader {
  margin-top: 60px;
  display: flex;
  align-items: center;
}

.searchheader .col-3,
.searchheader .col-2 {
  display: flex;
  align-items: center;
}

.searchheader .col-auto {
  margin-left: auto;
}

.recipe-body {
  margin-top: 20px;
  border: 3px solid #eaeef5;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
}

.recipe-body table {
  /* 设置表格内容居中显示 */
  text-align: center;
}
.recipe-body th,
.recipe-body td {
  /* 设置表格单元格内容居中显示 */
  text-align: center;
}

.footer {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-container {
  display: flex;
  align-items: center;
}

/* Responsive styles for screens smaller than 768px */
@media screen and (max-width: 767px) {
  .searchheader {
    margin-top: 40px;
  }

  .recipe-body {
    margin-top: 20px;
  }

  .footer {
    margin-top: 20px;
  }
}

/* Responsive styles for screens smaller than 576px */
@media screen and (max-width: 575px) {
  .searchheader {
    margin-top: 20px;
  }

  .recipe-body {
    margin-top: 10px;
  }

  .footer {
    margin-top: 10px;
  }
}

.recipe-body td .editbn,
.deletebn {
  background-color: transparent;
  color: #0d6efd;
  border-color: transparent;
}
