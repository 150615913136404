.SingleSelect {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.SingleSelect label {
    font-weight: 600;
}

.SingleSelect .SingleSelectOptions {
    display: flex;
    flex-direction: row;
}

.SingleSelect .SingleSelectOptions .form-check {
    margin-right: 1rem;
}

.SingleSelect .SingleSelectOptions label {
    height: auto;
}