.add-ingredient-form {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.add-ingredient-form h2 {
  text-align: center;
  margin-bottom: 20px;
}

.add-ingredient-form form {
  display: flex;
  flex-direction: column;
}

.add-ingredient-form label {
  margin-bottom: 10px;
}

.add-ingredient-form input,
.add-ingredient-form select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

/* Layout adjustments for specific labels */
.add-ingredient-form .labels-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.add-ingredient-form .labels-row label {
  flex: 1;
  margin-right: 20px;
  width: calc(50% - 5px); /* Subtract 5px for margin between inputs */
}

.add-ingredient-form .group-subgroup-row select {
  flex: 1;
  margin-right: 20px;
  width: calc(50% - 5px); /* Subtract 5px for margin between inputs */
}

.add-ingredient-form .image-row input {
  margin-top: 20px;
  margin-left: 20px;
  width: 470px; /* Subtract 10px for margin between inputs */
}

.add-ingredient-form .button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.add-ingredient-form button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.add-ingredient-form button[type='submit'] {
  background-color: #007bff;
  margin-right: 20px;
  color: #fff;
}

.add-ingredient-form button[type='button'] {
  background-color: #ccc;
  color: #000;
}

.add-ingredient-form button[type='button']:hover {
  opacity: 0.8;
}
