.RecipeAuthor {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.RecipeAuthor label {
    font-weight: 100;
}

.RecipeAuthor >label {
    font-weight: 600;
}