.Ingredients > label {
  font-weight: 600;
}

.recipe-input-form {
  margin-top: 20px;
  width: 100%;
}

.recipe-input-form h3 {
  text-align: center;
  margin-bottom: 20px;
}

.recipe-tags {
  width: 100%;
}

.tags-select div {
  position: relative;
}

.publish-status {
  margin-left: 12%;
  margin-bottom: 0.5em;
  font-weight: 700;
  font-size: 15px;
}
.publish-status label {
  display: inline-flex;
  align-items: center;
  margin-left: 12%;
  margin-bottom: 0.5em;
  font-weight: 700;
  font-size: 15px;
}
.publish-status input[type='radio'] {
  margin-right: 5px;
}

.navds-form-field {
  display: grid;
  align-items: center;
  grid-template-areas: 'label text';
  grid-template-rows: auto;
  grid-template-columns: 15% 70%;
  margin-bottom: 0.5em;
  margin-left: 12%;
}

.navds-text-field__input {
  grid-area: text;
  width: 100%;
  font-size: small !important;
}

.navds-label {
  grid-area: label;
}

.recipe-input-ingredient {
  display: flex;
  width: 61%;
  margin-left: 26%;
  margin-bottom: 10px;
}

.recipe-input-ingredient .navds-form-field {
  margin-bottom: 0.5em;
  grid-template-columns: 20% 70%;
}

.recipe-input-ingredient .navds-form-field label {
  width: 320px;
}

.recipe-input-ingredient-amount-unit {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.recipe-input-ingredient-amount-unit .navds-form-field {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.recipe-input-ingredient-translations {
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.recipe-input-ingredient-translations .navds-form-field {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-content: baseline;
}

.recipe-input-form-cooking-steps .navds-form-field {
  grid-template-columns: 5% 85%;
  margin-bottom: 0.1em;
  margin-left: 20%;
  margin-top: 10px;
}

.recipe-input-form-cooking-steps > div {
  padding-bottom: 10px;
  border-bottom: 1px dashed grey;
}

.ingre_add_delete {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  margin-top: 5px;
  width: 20%;
}

.cook_add_delete {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  width: 120%;
}

.save-recipe {
  float: right;
  margin-right: 12%;
}

.recipe-intro {
  margin-left: 140px;

}

.recipe-intro .language-text {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
}

.recipe-intro .language-text label {
  margin-right: 1rem;
  text-align: right;
  width: 2rem;
}

.recipe-intro textarea {
  width: 100%;
  min-height: 140px;
  margin-bottom: 10px;
}

.recipe-footer {
  margin-left: 140px;
  
}

.recipe-footer .language-text {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
}

.recipe-footer .language-text label {
  margin-right: 1rem;
  text-align: right;
  width: 2rem;
}

.recipe-footer textarea {
  width: 100%;
  min-height: 140px;
  margin-bottom: 10px;
}

@media (max-width: 576px) {
  .navds-form-field {
    /* grid-template-columns: 100%; */
    margin-left: 0;
  }

  .recipe-input-ingredient {
    width: 100%;
    margin-left: 0;
  }

  .recipe-input-form-cooking-steps .navds-form-field {
    margin-left: 0;
  }

  .save-recipe {
    margin-right: 0;
    margin-top: 10px;
    text-align: center;
  }
  .publish-status label {
    margin-left: 2px;
  }
}
