.MultiLanguageInput {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.MultiLanguageInput >label {
    font-weight: 600;
}

.MultiLanguageInput-language-input {
    display: grid;
    grid-template-columns: 40px auto;
    margin-bottom: 0.5rem;
}

.MultiLanguageInput-language-input label {
    grid-row-start: 1;
}

.MultiLanguageInput-language-input input {
    grid-row-start: 1;
}